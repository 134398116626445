import { Dialog, EmployeeSelectDialog, Layout, Button } from "@/components";
import globalUserStore from "@/global_store/globalUserStore";
import WebApi from "@/web/api";
import WebAuth from "@/web/WebAuth";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { LibAuthCode } from "lib/Auth";
import LibEnum from "lib/enum";
import { LibIRole } from "lib/interface/AuthInterface";
import { LibIEmployee } from "lib/interface/CompanyInterface";
import { observer } from "mobx-react";
import React from "react";
import CreateAuthRole from "./role/CreateRole";
import UpdateAuthRole from "./role/UpdateRole";
const styles = require('./auth.less');

const bgColors = [
  '#F4CE65',
  '#68A6F6',
  '#38D5B0',
  '#FF948F',
  '#58C8D0',
]

@observer
export default class Auth extends React.PureComponent<RouterPropsType> {

  private empModal = React.createRef<EmployeeSelectDialog>()

  private target: Record<any, any> = {};
  state = {
    roleList: [],
    createVisible: false,
    updateVisible: false,
    functionList: [],
    roleName: '',
    roleId: '',
    selectedEmployeeIdList: [],
  }

  componentDidMount() {
    this.findRoleAll();
  }

  findRoleAll = () => {
    return WebApi.auth_role_list({withEmployee: true})
    .then(data => {
      this.setState({
        roleList: data
      });
    });
  }

  render() {
    const { roleList } = this.state;

    return <Layout.Page>
      <Layout.Toolbar title="角色列表">
        <Button authCode={LibAuthCode.auth.create} type="primary" onClick={() => {
          this.setState({
            createVisible: true,
          });
        }}>新增角色</Button>
        <Button.Refresh onClick={() => {
          this.findRoleAll().then(() => {
            message.success('刷新成功');
          })
        }}/>
      </Layout.Toolbar>
      <div className="auth-page">
        {
          roleList.map((role: LibIRole, index) => {
            return <div key={role.roleId} className="role-item">
              <div style={{height: 3, background: bgColors[index % 5]}}></div>
              <div style={{
                borderBottom: '1px dashed #e9e9e9',
                padding: 15,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
                <div className="left">
                  <span className={role.editable === '0' ? 'icon admin' : 'icon'} style={{background: bgColors[index % 5]}}>
                    {
                      role.editable === '0' &&
                      <i className="iconfont icon-guanliyuan"/>
                    }
                    {
                      role.editable === '1' &&
                      <i className="iconfont icon-jiaose"/>
                    }
                  </span>
                  <span style={{fontSize: 14, fontWeight: 'bold'}}>{role.roleName}</span>
                </div>
                <div className="right">
                  {
                    role.editable === '0' &&
                    <div className="tip">{role.roleName}有最高权限，不可修改</div>
                  }
                  {
                    role.editable === '1' &&
                    <>
                      <Button.Edit authCode={LibAuthCode.auth.update} onClick={() => {
                        this.setState({
                          updateVisible: true,
                          roleName: role.roleName,
                          functionList: role.functionList,
                          roleId: role.roleId,
                        });
                      }}></Button.Edit>
                      <Button.Delete authCode={LibAuthCode.auth.delete} onClick={() => {
                        this.clickDeleteRole(role);
                      }}></Button.Delete>
                    </>
                  }
                </div>
              </div>
              <div className="auth-member-wrapper">
                {
                  Array.isArray(role.employeeList) &&
                  role.employeeList.map((employee: LibIEmployee) => {
                    return <div className="auth-member" key={employee.employeeId}>
                      <div className="auth-member-mobile" style={{background: bgColors[index % 5], cursor: employee.type == LibEnum.EMPLOYEE_TYPE.SYSTEM_PRESET.value ? 'default' : 'pointer'}}>
                        {
                          employee.fullName.substring(employee.fullName.length - 2, employee.fullName.length)
                        }
                        {
                          employee.type !== LibEnum.EMPLOYEE_TYPE.SYSTEM_PRESET.value && WebAuth.hasAuth(LibAuthCode.auth.update) && 
                          <div className="delete-btn" onClick={() => {
                            this.clickDeleteMember(employee, role);
                          }}>
                            <i className="iconfont icon-delete"/>
                          </div>
                        }
                      </div>
                      <div className="auth-member-name">{employee.fullName}</div>
                    </div>
                  })
                }
                {
                  WebAuth.hasAuth(LibAuthCode.auth.create) && <div className="auth-member">
                    <div className="auth-member-mobile" style={{
                      color: '#bbb',
                      border: '1px dashed #ccc',
                      cursor: 'pointer',
                      fontSize: 16,
                    }} onClick={() => {
                      this.setState({
                        selectedEmployeeIdList: role.employeeList.map(item => item.employeeId)
                      });
                      this.target = role;
                      this.empModal.current?.open();
                    }}>
                      <PlusOutlined/>
                    </div>
                    <div className="auth-member-name">
                      添加用户
                    </div>
                  </div>
                }
              </div>
            </div>
          })
        }
      </div>

      <EmployeeSelectDialog rowSelectionType="checkbox" ref={this.empModal} onOk={this.clickCreateMember} selectedRowKeys={this.state.selectedEmployeeIdList || []}/>

      <UpdateAuthRole
        roleId={this.state.roleId}
        roleName={this.state.roleName}
        visible={this.state.updateVisible}
        functionList={this.state.functionList}
        onCancel={() => this.setState({updateVisible: false})}
        onOk={() => {
          this.findRoleAll();
          this.setState({
            updateVisible: false,
          });
        }}
      />

      <CreateAuthRole visible={this.state.createVisible} onCancel={() => this.setState({createVisible: false})} onOk={() => {
        this.findRoleAll();
        this.setState({
          createVisible: false,
        });
      }}/>
    </Layout.Page>
  }

  clickCreateMember = (empIds) => {
    const employeeIdList = this.target.employeeList.map(item => item.employeeId) || [];
    WebApi.auth_role_update({
      roleId: this.target.roleId,
      employeeIdList: employeeIdList.concat(empIds),
    })
    .then(() => {
      showSuccess.save();
      this.findRoleAll();
      this.empModal.current?.close();
    });
  }

  clickDeleteMember = (employee, role: LibIRole) => {
    if (employee.employeeId === globalUserStore.userId) {
      Dialog.warning({
        title: '自己不能踢出自己'
      });
      return;
    }
    Dialog.confirm({
      content: `确定要踢出用户 “${employee.fullName}” 吗？`,
      onOk: () => {
        const employeeIdArray = role.employeeList?.map(item => item.employeeId) || [];
        WebApi.auth_role_update({
          roleId: role.roleId,
          employeeIdList: employeeIdArray.filter(item => item !== employee.employeeId),
        })
        .then(() => {
          showSuccess.save();
          this.findRoleAll();
        });
      }
    });
  }

  clickDeleteRole = role => {
    Dialog.confirm({
      content: `确定删除“${role.roleName}”吗？`,
      onOk: () => {
        WebApi.auth_role_delete({
          roleId: role.roleId
        })
        .then(() => {
          showSuccess.delete();
          this.findRoleAll();
        })
      }
    });
  }
}