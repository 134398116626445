import React from "react";
import RoleForm from './RoleForm';
import { Dialog } from "@/components";
import WebApi from "@/web/api";

export default class CreateAuthRole extends React.PureComponent<{
  visible: boolean;
  onOk(): void;
  onCancel(): void;
}> {
  private form = React.createRef<RoleForm>()

  render() {
    return <Dialog title="新增角色" block visible={this.props.visible} onCancel={this.props.onCancel} onOk={this.clickSave}>
      <RoleForm ref={this.form}/>
    </Dialog>
  }

  clickSave = () => {
    this.form.current?.validateFields()
    .then(values => {
      WebApi.auth_role_create({
        roleName: values.roleName,
        functionList: values.functionList,
      })
      .then(() => {
        showSuccess.save();
        this.props.onOk();
      });
    })
    .catch(e => {})
  }
}