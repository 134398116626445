import React from "react";
import RoleForm from './RoleForm';
import { Dialog } from "@/components";
import WebApi from "@/web/api";

export default class UpdateAuthRole extends React.PureComponent<{
  visible: boolean;
  roleId: string;
  roleName: string;
  functionList: number[];
  onOk(): void;
  onCancel(): void;
}> {
  private form = React.createRef<RoleForm>()

  render() {
    return <Dialog title="修改角色" block visible={this.props.visible} onCancel={this.props.onCancel} onOk={this.clickSave}>
      <RoleForm ref={this.form} roleName={this.props.roleName} functionList={this.props.functionList}/>
    </Dialog>
  }

  clickSave = () => {
    this.form.current?.validateFields()
    .then((values) => {
      WebApi.auth_role_update({
        roleId: this.props.roleId,
        roleName: values.roleName,
        functionList: values.functionList,
      }).then(() => {
        showSuccess.save();
        this.props.onOk();
      });
    })
    .catch(() => {})
  }
}