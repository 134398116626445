import { ViewTable } from "@/components";
import {Checkbox, Form, Input} from "antd";
import React from "react";
import { FormInstance } from "antd/lib/form";
import { LibAuthCode } from 'lib/Auth';

interface IProps {
  roleName?: string;
  functionList?: number[];
}

interface IAuthData {
  name: string;
  functionList: {
    name: string;
    code: number;
    checked?: boolean;
  }[];
}

interface IState {
  dataSource: IAuthData[]
}

export default class AuthRoleForm extends React.PureComponent<IProps, IState> {
  public form = React.createRef<FormInstance>()

  constructor(props) {
    super(props);

    this.state = {
      dataSource: [
        {
          name: '订货单',
          functionList: LibAuthCode.saleOrder
        },
        {
          name: '订货退货单',
          functionList: LibAuthCode.saleReturnOrder
        },
        {
          name: '进货单',
          functionList: LibAuthCode.purchaseOrder,
        },
        {
          name: '进货退货单',
          functionList: LibAuthCode.purchaseReturnOrder,
        },
        {
          name: '商品分类',
          functionList: LibAuthCode.category,
        },
        {
          name: '商品',
          functionList: LibAuthCode.product,
        },
        {
          name: '促销活动',
          functionList: LibAuthCode.activity,
        },
        {
          name: '首页广告',
          functionList: LibAuthCode.advert,
        },
        {
          name: '优惠券',
          functionList: LibAuthCode.coupon,
        },
        {
          name: '客户',
          functionList: LibAuthCode.customer,
        },
        {
          name: '供应商',
          functionList: LibAuthCode.supplier,
        },
        {
          name: '部门',
          functionList: LibAuthCode.department,
        },
        {
          name: '员工',
          functionList: LibAuthCode.employee,
        },
        {
          name: '系统日志',
          functionList: LibAuthCode.actionLog,
        },
        {
          name: '仓库',
          functionList: LibAuthCode.repository,
        },
        {
          name: '权限管理',
          functionList: LibAuthCode.auth,
        },
        {
          name: '通知公告',
          functionList: LibAuthCode.notice,
        },
      ].map(item => this.getFunctionList(item.name, item.functionList)),
    }
  }

  render() {
    return <Form ref={this.form} style={{width: 900}} labelCol={{span: 2}} initialValues={{
      roleName: this.props.roleName,
    }}>
      <Form.Item label="角色名称" name="roleName" rules={[{
        required: true,
        message: '角色名称不能为空'
      }]}>
        <Input placeholder="填写角色名称" style={{width: 200}} allowClear/>
      </Form.Item>
      <Form.Item label="权限明细">
        <ViewTable<IAuthData>
          dataSource={this.state.dataSource}
          size="small"
          rowKey={r => r.name}
          pagination={false}
          columns={[
            {
              title: '',
              dataIndex: 'name',
              width: 200,
              render: (v) => <strong>{v}</strong>
            },
            {
              title: '查看',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '查看');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '查看', e);
                }}/>
              },
            },
            {
              title: '新增',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '新增');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '新增', e);
                }}/>
              },
            },
            {
              title: '修改',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '修改');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '修改', e);
                }}/>
              },
            },
            {
              title: '删除',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '删除');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '删除', e);
                }}/>
              },
            },
            {
              title: '打印',
              dataIndex: 'print',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '打印');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '打印', e);
                }}/>
              },
            },
            // {
            //   title: '导入',
            //   dataIndex: 'import',
            //   width: 80,
            //   align: 'center',
            //   render: (v, row, index) => {
            //     if (row.authMap.import === undefined) return '--';
            //     return <Checkbox checked={row.authMap.import.value} onChange={e => {
            //       this.onChange(index, 'import', e);
            //     }}/>
            //   },
            // },
            {
              title: '导出',
              dataIndex: 'export',
              width: 80,
              align: 'center',
              render: (v, row, index) => {
                const current = row.functionList.find(item => item.name === '导出');
                if (!current) return '--';
                return <Checkbox checked={current.checked} onChange={e => {
                  this.onChange(index, '导出', e);
                }}/>
              },
            }
          ]}
        />
      </Form.Item>
    </Form>
  }

  private onChange = (index, actionType, e) => {
    const next = [...this.state.dataSource];
    if (e.target.checked) {
      next[index].functionList.forEach(item => {
        if (item.name === '查看' || item.name === actionType) {
          item.checked = true;
        }
      });
    } else {
      next[index].functionList.forEach(item => {
        if (actionType === '查看' || item.name === actionType) {
          item.checked = false;
        }
      });
    }
    this.setState({
      dataSource: next
    });
  }

  public validateFields = async (): Promise<{
    roleName: string;
    functionList: number[];
  }> => {
    const values = await this.form.current?.validateFields();
    return {
      roleName: values?.roleName,
      functionList: this.state.dataSource.map(item => item.functionList).flat().filter(item => item.checked).map(item => item.code),
    }
  }

  getFunctionList = (name: string, module: {[key: string]: number}) => {
    const functionList = this.props.functionList || [];
    return {
      name,
      functionList: [
        {
          name: '查看',
          code: module.view,
          checked: functionList.includes(module.view),
        },
        {
          name: '新增',
          code: module.create,
          checked: functionList.includes(module.create),
        },
        {
          name: '修改',
          code: module.update,
          checked: functionList.includes(module.update),
        },
        {
          name: '删除',
          code: module.delete,
          checked: functionList.includes(module.delete),
        },
        {
          name: '打印',
          code: module.print,
          checked: functionList.includes(module.print),
        },
        {
          name: '导出',
          code: module.export,
          checked: functionList.includes(module.export),
        }
      ]
    }
  }
}